@font-face {
  font-family: "AUthenia";
  font-display: swap;
  src: url("../../assets/FontsFree-Net-Authenia.ttf") format("truetype");
}

body {
  font-family: "Arimo", Helvetica, Arial, Lucida, sans-serif;
  font-size: 15px;
  color: #11282c;
  line-height: 2em;
}

.et_pb_with_background {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
}

.et_pb_fullwidth_section {
  padding: 0;
}

.et_pb_section_0 {
  height: 400px;
  background-image: linear-gradient(
      270deg,
      rgba(98, 186, 70, 0.05) 0%,
      rgba(0, 151, 134, 0.71) 100%
    ),
    url("../../assets/Internal-Banner-5.jpg");
}

.et_pb_fullwidth_header {
  padding: 50px 0;
  position: relative;
  background-position: 50%;
  background-size: cover;
}

.et_pb_fullwidth_header_0 {
  height: 400px;
  padding-top: 160px;
}

.et_pb_fullwidth_header_container {
  position: relative;
  z-index: 3;
  width: 80%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.et_pb_fullwidth_header_container.left {
  display: flex;
  flex-flow: row;
}

.et_pb_fullwidth_header:not(.et_pb_fullscreen)
  .et_pb_fullwidth_header_container {
  max-width: 1400px;
}

.et_pb_fullwidth_header .et_pb_fullwidth_header_overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
}

.et_pb_fullwidth_header .et_pb_fullwidth_header_scroll {
  width: 100%;
  min-height: 30px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.et_pb_fullwidth_header
  .et_pb_fullwidth_header_container.left
  .header-content-container {
  width: 100%;
}

.et_pb_fullwidth_header
  .et_pb_fullwidth_header_container.left
  .header-content-container.center {
  align-self: center;
}

.et_pb_fullwidth_header .header-content {
  padding: 10px;
  text-align: center;
}

.et_pb_fullwidth_header_subhead {
  font-family: "AUthenia", Helvetica, Arial, Lucida, sans-serif;
  font-size: 100px;
  text-align: center;
  color: #fff;
}

.et_pb_section_0.section_has_divider.et_pb_bottom_divider
  .et_pb_bottom_inside_divider {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI3MHB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTEyODAgMy40QzEwNTAuNTkgMTggMTAxOS40IDg0Ljg5IDczNC40MiA4NC44OWMtMzIwIDAtMzIwLTg0LjMtNjQwLTg0LjNDNTkuNC41OSAyOC4yIDEuNiAwIDMuNFYxNDBoMTI4MHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAyNC4zMWM0My40Ni01LjY5IDk0LjU2LTkuMjUgMTU4LjQyLTkuMjUgMzIwIDAgMzIwIDg5LjI0IDY0MCA4OS4yNCAyNTYuMTMgMCAzMDcuMjgtNTcuMTYgNDgxLjU4LTgwVjE0MEgweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDUxLjc2Yy0yMDEgMTIuNDktMjQyLjQzIDUzLjQtNTEzLjU4IDUzLjQtMzIwIDAtMzIwLTU3LTY0MC01Ny00OC44NS4wMS05MC4yMSAxLjM1LTEyNi40MiAzLjZWMTQwaDEyODB6Ii8+PC9nPjwvc3ZnPg=="); /* SVG Data */
  background-size: 100% 70px;
  bottom: 0;
  height: 70px;
  z-index: 10;
  transform: scale(1, 1);
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}

/* Media queries for responsive adjustments */
@media (min-width: 981px) {
  .et_pb_section {
    padding: 4% 0;
  }
}

@media only screen and (min-width: 1750px) {
  .et_pb_section {
    padding: 70px 0;
  }
}

@media (max-width: 768px) {
  .et_pb_fullwidth_header_subhead {
    font-size: 70px; /* Smaller font size for screens smaller than 768px */
  }
}
