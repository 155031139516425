.container {
  margin-top: 3%;
  margin-bottom: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.formDiv {
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  -webkit-box-shadow: -1px 8px 15px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: -1px 8px 15px 0px rgba(0, 0, 0, 0.44);
  box-shadow: -1px 8px 15px 0px rgba(0, 0, 0, 0.44);
}

.creditCard {
  margin-bottom: 10%;
}
